.aside {
  overflow: hidden;
}

.aside-menu {
  height: 100%;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.menu-nav {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

li.menu-item {
  background: #3d9cfc;
}

li.menu-item-highlighted {
  background-color: #2491ff;
}

.menu-nav > li.menu-item:last-child {
  margin-top: auto !important;
  background: #3591fc;
}

.menu-item {
  max-height: 56px;
  min-height: 56px;

  &.menu-item-submenu {
    max-height: none;
    min-height: 56px;
    flex: 0 1 auto !important;

    .menu-toggle,
    .menu-link {
      min-height: 56px !important;
    }
  }

  .menu-arrow {
    &:before {
      content: "\f105" !important;
      margin-bottom: 2px;
      font-size: 16px;
      font-family: "Font Awesome 5 Pro" !important;
    }
  }
}
