@import "~perfect-scrollbar";
@import "fontawesome/css/all.css";
@import "../fonts/Inter.ttf";

html {
    font-family: 'Inter', sans-serif;
}

@supports (font-variation-settings: normal) {
    html {
        font-family: 'Inter var', sans-serif;
    }
}

.main-root-content {
    min-height: 100vh;
}

@media (max-width: 992px) {
    .topbar {
        .topbar-mobile-on & {
            padding: 33px 15px;
            background: white;
        }
    }
}

@media(min-width: 992px) {
    .aside-fixed .aside {
        top: 12px;
        left: 12px;
        //bottom: 38px;
        bottom: 12px;
    }

    .app-header-container {
        box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
        border-radius: 8px;
    }

    .aside {
        border-radius: 10px;
    }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) { overflow-y: visible !important; }

.btn.btn-icon.btn-sm {
    height: calc(1.35em + 1.1rem + 2px);
    width: auto;
    padding-left: 1rem;
    padding-right: 1rem;
}

.btn.btn-hover-primary:focus:not(.btn-text), {
    color: var(--dark) !important;
    background-color: transparent !important;
    border-color: transparent !important;
}

.app-content-container {
    border-radius: 8px;
}

.app-footer-container {
    border-radius: 8px;
}

.channel-row {
    display: flex;
    flex-wrap: wrap;
    margin-right: -12.5px;
    margin-left: -12.5px;
}

.channel-image {
    height: 120px;
    border-radius: 8px;
    object-fit: contain;
}

.card.card-custom.card-stretch.gutter-b {
    height: calc(100% - 25px);
}

.aside-menu {
    .menu-text {
        font-weight: 500 !important;
    }
}

.dropdown-toggle {
    &::after {
        content: "\f107" !important;
        font-family: 'Font Awesome 5 Pro' !important;
        font-size: 1rem !important;
    }
}

.content {
    padding-bottom: 0;

    & > .flex-column-fluid > .container-fluid {
        @media (min-width: 992px) {
            padding: 0 1.5rem;
        }
    }
}

.form-control {
    border-radius: 4px;
}

.header {
    @media (min-width: 992px) {
        padding-top: 12px;
    }
}

.button, label {
    font-weight: 500 !important;
}

.cur-na {
    cursor: not-allowed !important;
}

.row {
    margin-left: -12px;
    margin-right: -12px;
}

[class*="col-"] {
    padding-right: 12px;
    padding-left: 12px;
}

button {
    font-weight: 500;
}

[role="row"] * {
    line-height: 1.5 !important;
}

.topbar {
    align-items: center;
}

.text-2-line {
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
}

#root {
    overflow: auto;
    //background: url("../images/background.jpg") center center no-repeat;
    //background-size: cover;
}

.overflow-x-hidden-y-auto {
    overflow-x: hidden;
    overflow-y: auto;
}

.background-f5f5f5 {
    background: var(--hactieuho-f5f5f5) !important;
}

.hactieuho-grey {
    color: var(--hactieuho-grey) !important;
}

.swal2-actions>.swal2-confirm {
    margin-right: 8px;
}

.swal2-actions>.swal2-cancel {
    margin-left: 8px;
}

.modal-footer>button.btn-primary {
    margin-left: 16px;
}

:root {
    --hactieuho-grey: #68758C;
    --hactieuho-f5f5f5: #F5F5F5;
}
